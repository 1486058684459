/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../../props/Icon';

const IconNext: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M15.9932 8.01072V8.01074C15.9921 7.55165 15.7946 7.11498 15.4507 6.81088L7.99894 0.252379V0.252379C7.58873 -0.0834227 6.98397 -0.0231043 6.64817 0.387103C6.32888 0.777152 6.36568 1.34773 6.73244 1.69353L12.4897 6.75955V6.75955C12.5586 6.82064 12.5648 6.92596 12.5038 6.9948C12.4723 7.03029 12.4272 7.05067 12.3797 7.05085H0.971841H0.971841C0.441715 7.05085 0.0119629 7.4806 0.0119629 8.01073C0.0119629 8.54085 0.441715 8.9706 0.971841 8.9706H12.3777V8.9706C12.4701 8.97149 12.5443 9.04712 12.5434 9.13952C12.543 9.18654 12.5227 9.2312 12.4877 9.26257L6.73044 14.3286V14.3286C6.31804 14.6617 6.25375 15.266 6.58685 15.6784C6.91995 16.0908 7.52431 16.1551 7.93671 15.822C7.95752 15.8052 7.97761 15.7876 7.99694 15.7691L15.45 9.21057V9.21057C15.7943 8.9067 15.9921 8.46996 15.9933 8.01072L15.9932 8.01072Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconNext;
