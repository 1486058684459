/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

import colors from './colors';
import control from './control';
import font from './font';
import screens from './screens';
import spacing from './spacing';

const breakpoints = [screens.sm, screens.md, screens.lg, screens.xl];

const theme = {
  breakpoints,
  colors,
  defaultSpace: 6,
  font,
  screens,
  spacing,
};

export default theme;
export { colors, control, font, screens, spacing };
