import classnames from 'classnames';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';

const Group = styled.fieldset.attrs(props => ({
  className: classnames(props.className, 'mb-4'),
}))<ThemeProps<DefaultTheme>>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  border: 0;

  &.alt {
    background-color: #fbfbfc;
    border: 1px solid rgba(228, 231, 237, 0.4);
  }

  h2 {
    text-align: center;
  }
`;

export const Description = styled.p``;

export const Title = styled.h2``;

export default Group;
