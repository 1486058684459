import { UserType } from '@innedit/innedit-type';

import {
  AUTH_USER_IS_CONNECTED,
  USER_LOAD_SUCCESS,
  USER_UNSUBSCRIBE,
} from '../constants';

export interface ReducerUserProps {
  authUser?: any;
  item?: UserType;
  unsubscribe?: () => void;
}

const initialState: ReducerUserProps = {
  authUser: undefined,
  item: undefined,
  unsubscribe: undefined,
};

const reducerUser = (
  state = initialState,
  action: { [key: string]: any; type: string },
): ReducerUserProps => {
  switch (action.type) {
    case AUTH_USER_IS_CONNECTED: {
      return { ...state, authUser: action.authUser };
    }

    case USER_LOAD_SUCCESS: {
      return { ...state, item: action.user };
    }

    case USER_UNSUBSCRIBE: {
      return { ...state, unsubscribe: action.unsubscribe };
    }

    default:
      return state;
  }
};

export default reducerUser;
