import { DataField, DataFieldProps } from '@innedit/formidable';
import React, { FC } from 'react';

interface DataCheckoutDeliveryMethodProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataCheckoutDeliveryMethod: FC<DataCheckoutDeliveryMethodProps> = ({
  formValues,
  formName,
  name,
  params,
  ...props
}) => (
  <DataField
    {...props}
    className="flex items-center space-x-3"
    componentType="input"
    formName={formName}
    formValues={formValues}
    name={name}
    options={[
      {
        label: 'form.checkout.livraison.method.store.label',
        value: 'store',
      },
      {
        label: 'form.checkout.livraison.method.carrier.label',
        params: {
          count: params?.livraison ?? 0,
        },
        value: 'carrier',
      },
    ]}
    params={params}
    type="radio"
  />
);

export default DataCheckoutDeliveryMethod;
