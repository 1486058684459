import styled from 'styled-components';

import colors from '../../../styles/colors';
import font from '../../../styles/font';
import spacing from '../../../styles/spacing';

export const Message = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(${spacing[4]});
  font-size: ${(): string => font.size.xs[0] as string};
  color: ${colors.danger[500]};
`;

export const Template = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: ${spacing[4]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export default styled.div``;
