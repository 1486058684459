import styled from 'styled-components';

import spacing from '../../../styles/spacing';
import Control from './Control';

const Textarea = styled(Control).attrs({ as: 'textarea' })`
  line-height: 1.5rem;
  padding-top: ${spacing[3]};
  padding-bottom: ${spacing[3]};
`;

export default Textarea;
