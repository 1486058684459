/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../../props/Icon';

const IconAdd: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0V0ZM8 14C6.81331 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19975 1.88378 7.99335 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66557 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C13.9982 9.59076 13.3655 11.1159 12.2407 12.2407C11.1159 13.3655 9.59076 13.9982 8 14Z"
      fill={color || 'currentColor'}
    />
    <path
      d="M11.5 7.04537H9.12134C9.07714 7.04537 9.03475 7.02781 9.00349 6.99655C8.97223 6.9653 8.95467 6.9229 8.95467 6.8787V4.50003C8.95415 4.33134 8.8869 4.16971 8.76762 4.05043C8.64833 3.93114 8.4867 3.86389 8.31801 3.86337H7.68201C7.51332 3.86389 7.35168 3.93114 7.2324 4.05043C7.11312 4.16971 7.04587 4.33134 7.04534 4.50003V6.8787C7.04534 6.9229 7.02778 6.9653 6.99653 6.99655C6.96527 7.02781 6.92288 7.04537 6.87867 7.04537H4.50001C4.33138 7.04572 4.16974 7.11282 4.05044 7.232C3.93114 7.35117 3.86387 7.51274 3.86334 7.68137V8.31803C3.86387 8.48673 3.93112 8.64836 4.0504 8.76764C4.16968 8.88693 4.33132 8.95417 4.50001 8.9547H6.87867C6.92288 8.9547 6.96527 8.97226 6.99653 9.00352C7.02778 9.03477 7.04534 9.07716 7.04534 9.12137V11.5C7.04587 11.6687 7.11314 11.8302 7.23244 11.9494C7.35174 12.0686 7.51338 12.1357 7.68201 12.136H8.31801C8.48664 12.1357 8.64827 12.0686 8.76758 11.9494C8.88688 11.8302 8.95415 11.6687 8.95467 11.5V9.12137C8.95467 9.07716 8.97223 9.03477 9.00349 9.00352C9.03475 8.97226 9.07714 8.9547 9.12134 8.9547H11.5C11.6687 8.95417 11.8303 8.88693 11.9496 8.76764C12.0689 8.64836 12.1361 8.48673 12.1367 8.31803V7.68137C12.1361 7.51274 12.0689 7.35117 11.9496 7.232C11.8303 7.11282 11.6686 7.04572 11.5 7.04537Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconAdd;
