/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

module.exports = {
  white: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#feffff',
    300: '#fdfffe',
    400: '#fcfefe',
    500: '#FBFEFD',
    600: '#e2e5e4',
    700: '#bcbfbe',
    800: '#979898',
    900: '#7b7c7c',
  },
  black: {
    50: '#f2f2f2',
    100: '#e6e6e6',
    200: '#c0c0c0',
    300: '#9a9a9b',
    400: '#4e4f4f',
    500: '#020304',
    600: '#020304',
    700: '#020203',
    800: '#010202',
    900: '#010102',
  },
  light: {
    50: '#fefefe',
    100: '#fefdfc',
    200: '#fcfbf8',
    300: '#faf8f4',
    400: '#f6f2eb',
    500: '#f2ede3',
    600: '#dad5cc',
    700: '#b6b2aa',
    800: '#918e88',
    900: '#77746f',
  },
  dark: {
    50: '#f3f3f3',
    100: '#e7e7e7',
    200: '#c3c3c2',
    300: '#9f9e9e',
    400: '#565655',
    500: '#0e0d0c',
    600: '#0d0c0b',
    700: '#0b0a09',
    800: '#080807',
    900: '#070606',
  },
  neutral: {
    50: '#f7f7f7',
    100: '#f0efef',
    200: '#d9d7d6',
    300: '#c2bfbd',
    400: '#94908c',
    500: '#66605a',
    600: '#5c5651',
    700: '#4d4844',
    800: '#3d3a36',
    900: '#322f2c',
  },
  primary: {
    50: '#f6f5f4',
    100: '#eeecea',
    200: '#d4cfca',
    300: '#b9b1aa',
    400: '#85776b',
    500: '#513d2b',
    600: '#493727',
    700: '#3d2e20',
    800: '#31251a',
    900: '#281e15',
  },
  secondary: {
    50: '#fbfaf8',
    100: '#f7f4f1',
    200: '#ebe5dd',
    300: '#ded5c8',
    400: '#c6b59e',
    500: '#ad9575',
    600: '#9c8669',
    700: '#806e57',
    800: '#685946',
    900: '#554939',
  },
  tertiary: {
    50: '#fdfdfd',
    100: '#fafafa',
    200: '#f3f3f3',
    300: '#ececec',
    400: '#dedede',
    500: '#d0d0d0',
    600: '#bbbbbb',
    700: '#9c9c9c',
    800: '#7d7d7d',
    900: '#666666',
  },
  quaternary: {
    50: '#fffafc',
    100: '#fff6f9',
    200: '#ffe8ef',
    300: '#ffdae5',
    400: '#ffbed2',
    500: '#ffa2bf',
    600: '#e692ac',
    700: '#bf7a8f',
    800: '#996173',
    900: '#7d4f5e',
  },
  success: {
    50: '#f4faf4',
    100: '#e8f4e8',
    200: '#c7e4c7',
    300: '#a5d4a5',
    400: '#61b361',
    500: '#1d931d',
    600: '#1a841a',
    700: '#166e16',
    800: '#115811',
    900: '#0e480e',
  },
  info: {
    50: '#f2f8fd',
    100: '#e6f2fb',
    200: '#c0def5',
    300: '#9bcaef',
    400: '#4fa3e2',
    500: '#047bd6',
    600: '#046fc1',
    700: '#035ca1',
    800: '#024a80',
    900: '#023c69',
  },
  warning: {
    50: '#fffcf2',
    100: '#fffae6',
    200: '#fff2bf',
    300: '#ffeb99',
    400: '#ffdb4d',
    500: '#ffcc00',
    600: '#e6b800',
    700: '#bf9900',
    800: '#997a00',
    900: '#7d6400',
  },
  danger: {
    50: '#fdf5f4',
    100: '#faece9',
    200: '#f3cfc8',
    300: '#ebb3a6',
    400: '#dd7964',
    500: '#ce4021',
    600: '#b93a1e',
    700: '#9b3019',
    800: '#7c2614',
    900: '#651f10',
  },
  transparent: 'transparent',
};
