/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import border from './border';
import colors from './colors';
import spacing from './spacing';

const control = {
  border: {
    color: {
      base: colors.light[600],
      hover: colors.light[700],
    },
    size: { base: 1 },
    type: 'solid',
  },
  icon: {
    color: {
      base: colors.primary,
    },
    size: {
      base: 16,
    },
  },
  size: {
    height: {
      base: '2.5rem',
    },
  },
  padding: {
    x: {
      base: spacing[6],
      small: spacing[4],
      icon: spacing[6],
      iconSmall: spacing[4],
    },
    y: {
      base: 0,
      small: 0,
    },
  },
  radius: {
    base: border.radius.sm,
  },
};

export default control;
