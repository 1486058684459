import user, { ReducerUserProps } from './user';

export interface StateProps {
  user: ReducerUserProps;
}

const reducers = {
  user,
};

export default reducers;
