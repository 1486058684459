import { DataProps } from '@innedit/formidable';
import React, { ReactElement } from 'react';

import DataCheckoutDeliveryMethod from './Checkout/Delivery/Method';
import DataPaymentElement from './Checkout/Payment/Element';
import DataCountry from './Country';

const Data = (
  props: DataProps & {
    formName: string;
    params: { [key: string]: any };
  },
): ReactElement<any, any> | null => {
  const { componentType, name } = props;

  switch (componentType) {
    case 'checkout.delivery.method': {
      if (!name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return <DataCheckoutDeliveryMethod {...props} name={name} />;
    }
    case 'country': {
      if (!name) {
        return <div>country : erreur de paramètre : name est obligatoire</div>;
      }

      return <DataCountry {...props} name={name} />;
    }

    case 'payment.element':
      return <DataPaymentElement {...props} />;

    default:
  }

  return null;
};

export default Data;
