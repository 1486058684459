import styled, { DefaultTheme, ThemeProps } from 'styled-components';

import colors from '../../styles/colors';

export const Message = styled.div<ThemeProps<DefaultTheme>>`
  color: ${colors.danger[500]};
  text-align: center;
`;

const Form = styled.form``;

export default Form;
