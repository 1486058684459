import React, { ComponentType, FC } from 'react';

import IconAdd from '../../icons/Add';
import IconBack from '../../icons/Back';
import IconDelete from '../../icons/Delete';
import IconNext from '../../icons/Next';
import IconProps from '../../props/Icon';

const Icon: FC<{ color?: string; size?: number; value: FC | string }> =
  function ({ color, size, value }) {
    if (typeof React.Component === typeof value) {
      const IconCmp: ComponentType<IconProps> = value as ComponentType;

      return <IconCmp color={color} size={size} />;
    }

    switch (value) {
      case 'add': {
        return <IconAdd color={color} size={size} />;
      }

      case 'back': {
        return <IconBack color={color} size={size} />;
      }

      case 'next': {
        return <IconNext color={color} size={size} />;
      }

      case 'remove': {
        return <IconDelete color={color} size={size} />;
      }

      default:
    }

    return <div>Icon</div>;
  };
export default Icon;
