import styled, { css } from 'styled-components';

import ButtonProps from '../../props/Button';
import colors from '../../styles/colors';

const getButtonStatusStyles = (props: ButtonProps) => {
  switch (props.status) {
    case 'neutral':
      return css`
        background: ${colors.light[400]};
        color: ${colors.primary[500]};
        // box-shadow: inset 0 0 0 1px ${colors.neutral[300]};
        border: 1px solid ${colors.neutral[300]};
        &:hover {
          background: ${colors.secondary[400]};
          color: ${colors.primary[500]};
        }

        padding: 0.5rem 1.5rem;
      `;
    case 'link':
      return css`
        background: transparent;
        color: ${colors.dark[400]};
        padding: 0 !important;
        min-height: auto !important;
        line-height: inherit; !important;
        &:hover {
          background: transparent;
          color: ${colors.dark[600]};
        }
        outline: none;
        
        &:focus {
          outline: none;
        }
      `;
    case 'secondary':
      return css`
        outline: none;
        background: ${colors.secondary[500]};
        color: ${colors.white[500]};
        &:hover {
          background: ${colors.secondary[700]};
          color: ${colors.white[500]};
        }
        &:focus {
          outline: none;
        }

        padding: 0.5rem 1.5rem;
      `;
    case 'light':
      return css`
        outline: none;
        background: ${colors.light[500]};
        color: ${colors.black[500]};
        &:hover {
          background: ${colors.light[700]};
          color: ${colors.black[500]};
        }
        &:focus {
          outline: none;
        }

        padding: 0.5rem 1.5rem;
      `;
    case 'danger':
      return css`
        background: ${colors.danger[500]};
        color: ${colors.light['300']};
        &:hover {
          background: ${colors.danger[600]};
          color: ${colors.light};
        }

        padding: 0.5rem 1.5rem;
      `;
    default:
      return css`
        background: ${colors.secondary[700]};
        color: ${colors.white[500]};
        &:hover {
          background: ${colors.secondary[900]};
          color: ${colors.white[500]};
        }

        padding: 0.5rem 1.5rem;
      `;
  }
};

const ButtonSC = styled.button<ButtonProps>`
  ${props => getButtonStatusStyles(props)};

  &:disabled {
    opacity: 0.2;
  }
`;

export default ButtonSC;
