export const GLOBAL_KEY = '__PRELOADED_STATE__';
export const MODAL_ID = 'modal-root';

export const AUTH_USER_IS_CONNECTED = 'AUTH_USER_IS_CONNECTED';

export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';
export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UNSUBSCRIBE = 'USER_UNSUBSCRIBE';
