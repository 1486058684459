import { Dispatch } from '@innedit/formidable';
import { UserData } from '@innedit/innedit';

import {
  AUTH_USER_IS_CONNECTED,
  USER_LOAD_ERROR,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOGOUT,
} from '../constants';

export function load(authUser: { isAnonymous: boolean; uid: string }) {
  return (dispatch: Dispatch): void => {
    dispatch({
      authUser,
      type: AUTH_USER_IS_CONNECTED,
    });

    const { uid, isAnonymous } = authUser;

    if (!isAnonymous) {
      dispatch({
        type: USER_LOAD_REQUEST,
      });

      const userData = new UserData();
      userData
        .find({
          wheres: {
            uid,
          },
        })
        .then(users => {
          dispatch({
            type: USER_LOAD_SUCCESS,
            user: 1 === users.length ? users[0] : undefined,
          });

          return true;
        })
        .catch(error => {
          dispatch({
            error: error.message,
            type: USER_LOAD_ERROR,
          });
        });

      // UtilisateurModel.get(uid)
      //   .then(user => {
      //     if (!user) {
      //       dispatch({
      //         error: "L'utilisateur n'existe pas ou a été supprimé",
      //         type: USER_LOAD_ERROR,
      //       });
      //     } else {
      //       dispatch({
      //         user,
      //         type: USER_LOAD_SUCCESS,
      //       });
      //     }
      //
      //     return true;
      //   })
      //   .catch(error => {
      //     dispatch({
      //       error: error.message,
      //       type: USER_LOAD_ERROR,
      //     });
      //   });

      // dispatch({
      //   unsubscribe,
      //   type: USER_UNSUBSCRIBE,
      // });
    }
  };
}

export function logout() {
  return (dispatch: Dispatch): void => {
    dispatch({ type: USER_LOGOUT });
  };
}
