import styled from 'styled-components';

import down from '../../../images/down.svg';
import control from '../../../styles/control';
import Control from './Control';

const Select = styled(Control).attrs({ as: 'select' })`
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;

  padding-right: ${control.padding.x.base};
  background-image: url(${down});
  background-repeat: no-repeat, repeat;
  background-position: right ${control.padding.x.base} top 50%, 0 0;
  background-size: 0.75rem auto, 100%;
  option {
    font-weight: normal;
  }
`;

export default Select;
