import styled from 'styled-components';

import spacing from '../../styles/spacing';

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * + * {
    margin-left: ${spacing[3]};
  }

  @media screen and (max-width: 640px) {
    > * + * {
      margin-left: 0;
    }
  }
`;

export default Columns;
