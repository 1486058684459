import { Box, DataProps } from '@innedit/formidable';
import { PaymentElement } from '@stripe/react-stripe-js';
import React, { FC } from 'react';

type DataPaymentElementProps = DataProps;

const DataPaymentElement: FC<DataPaymentElementProps> = function ({
  className,
}) {
  return (
    <Box className={`${className}`}>
      <PaymentElement />
    </Box>
  );
};

export default DataPaymentElement;
