const checkIfIsVisible = (
  client: any,
  windowWidth: number,
  windowHeight: number,
  marginTop = 0,
  marginRight = 0,
  marginBottom = 0,
  marginLeft = 0,
): boolean => {
  const { top, right, bottom, left } = client;

  return (
    top - marginTop >= 0 &&
    right - marginRight <= windowWidth &&
    bottom - marginBottom <= windowHeight &&
    left - marginLeft >= 0
  );
};

export default checkIfIsVisible;
