exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-order-[id]-tsx": () => import("./../../../src/pages/order/[id].tsx" /* webpackChunkName: "component---src-pages-order-[id]-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-product-produit-pathname-tsx": () => import("./../../../src/pages/product/{Produit.pathname}.tsx" /* webpackChunkName: "component---src-pages-product-produit-pathname-tsx" */),
  "component---src-pages-search-[query]-[page]-tsx": () => import("./../../../src/pages/search/[query]/[page].tsx" /* webpackChunkName: "component---src-pages-search-[query]-[page]-tsx" */),
  "component---src-pages-search-[query]-tsx": () => import("./../../../src/pages/search/[query].tsx" /* webpackChunkName: "component---src-pages-search-[query]-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-stripe-success-tsx": () => import("./../../../src/pages/stripe/success.tsx" /* webpackChunkName: "component---src-pages-stripe-success-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-produits-tsx": () => import("./../../../src/templates/produits.tsx" /* webpackChunkName: "component---src-templates-produits-tsx" */)
}

