import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';

import IconLoading from '../../icons/Loading';
import ButtonProps from '../../props/Button';
import checkIfIsVisible from '../../utils/checkIfIsVisible';
import Icon from '../Icon';
import ButtonSC from './styles';

const Button: FC<PropsWithChildren<ButtonProps>> = function ({
  children,
  className,
  clickOnVisible = false,
  onClick,
  iconColor,
  iconLeft,
  iconRight,
  iconSize,
  isInfinite = false,
  loading,
  to,
  ...others
}) {
  const nodeRef = useRef<HTMLButtonElement>(null);
  const props: { to?: string; as?: any } = {};

  useEffect(() => {
    const isVisible = () => {
      if (
        !nodeRef ||
        !nodeRef.current ||
        !nodeRef.current.getBoundingClientRect
      ) {
        return;
      }

      const html = document.documentElement;
      const boundingClientRect = nodeRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth || html.clientWidth;
      const windowHeight = window.innerHeight || html.clientHeight;

      if (checkIfIsVisible(boundingClientRect, windowWidth, windowHeight)) {
        if (!isInfinite) {
          removeListener();
        }

        if (clickOnVisible) {
          nodeRef.current.click();
        }
      }
    };

    const attachListener = () => {
      window.addEventListener('scroll', isVisible);
      window.addEventListener('resize', isVisible);
    };

    const removeListener = () => {
      window.removeEventListener('scroll', isVisible);
      window.removeEventListener('resize', isVisible);
    };

    if (clickOnVisible) {
      attachListener();
      isVisible();
    }

    return () => {
      removeListener();
    };
  }, [clickOnVisible, isInfinite, nodeRef]);

  if (to) {
    props.to = to;
    props.as = Link;
  }

  return (
    <ButtonSC
      ref={nodeRef}
      className={classnames(
        className,
        'relative flex items-center space-x-3 cursor-pointer box-border inline-block align-top',
        {
          'justify-between': iconLeft || iconRight || loading,
          'justify-center': !iconLeft && !iconRight && !loading,
        },
      )}
      onClick={onClick}
      {...props}
      {...others}
    >
      {iconLeft && <Icon color={iconColor} size={iconSize} value={iconLeft} />}
      {children && <div>{children}</div>}
      {iconRight && (
        <Icon color={iconColor} size={iconSize} value={iconRight} />
      )}
      {loading && <IconLoading />}
    </ButtonSC>
  );
};

export default Button;
