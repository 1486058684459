/* eslint-disable @typescript-eslint/ban-ts-comment */

import { DataField, DataFieldProps } from '@innedit/formidable';
import React, { FC } from 'react';

import codeIso from '../i18n/ISO3166-1.json';

type DataCountryProps = Omit<DataFieldProps, 'componentType'>;

const DataCountry: FC<DataCountryProps> = ({
  formValues,
  formName,
  name,
  ...props
}) => {
  const options: { value: string; label: string }[] = Object.keys(
    codeIso as { [value: string]: string },
  ).map(value => ({
    value,
    label: codeIso[value] as string,
  }));

  // const options: { label: string; value: string }[] = [
  //   {
  //     label: 'France',
  //     value: 'FR',
  //   },
  // ];

  return (
    <DataField
      {...props}
      componentType="select"
      formName={formName}
      formValues={formValues}
      name={name}
      options={options}
    />
  );
};

export default DataCountry;
