import styled from 'styled-components';

import spacing from '../../styles/spacing';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-bottom: ${spacing[4]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Column;
