/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../../props/Icon';

const IconDelete: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M8.71932 0C9.90646 0 10.8779 0.919639 10.9605 2.08583L10.9666 2.24609L10.9654 2.8748L13.7527 2.87603C14.1989 2.87603 14.5614 3.23846 14.5614 3.68472C14.5614 4.09523 14.2569 4.43301 13.8624 4.48602L13.7527 4.49464L13.1227 4.49341V13.7527C13.1227 14.9398 12.2031 15.9125 11.0369 15.9938L10.8766 16H3.68472C2.49757 16 1.52616 15.0791 1.44356 13.9129L1.4374 13.7527V4.49341H0.808691C0.362432 4.49341 0 4.13221 0 3.68472C0 3.27544 0.304492 2.93767 0.698975 2.88343L0.808691 2.87603H3.59473V2.24732C3.59473 1.06017 4.5156 0.087526 5.68179 0.00493105L5.84205 0H8.71932V0ZM11.5054 4.49341H3.05601V13.7527C3.05601 14.0658 3.28407 14.3247 3.58364 14.374L3.68472 14.3814H10.8766C11.1885 14.3814 11.4486 14.1533 11.4967 13.855L11.5054 13.7527V4.49341ZM5.84205 6.47199C6.25256 6.47199 6.59034 6.77648 6.64335 7.17097L6.65198 7.28068V11.5953C6.65198 12.0416 6.28955 12.404 5.84205 12.404C5.43278 12.404 5.095 12.0995 5.04076 11.7051L5.03336 11.5953V7.28068C5.03336 6.83442 5.39579 6.47199 5.84205 6.47199V6.47199ZM8.71932 6.47199C9.12859 6.47199 9.46637 6.77648 9.52061 7.17097L9.52801 7.28068V11.5953C9.52801 12.0416 9.16558 12.404 8.71932 12.404C8.30881 12.404 7.97103 12.0995 7.91802 11.7051L7.90939 11.5953V7.28068C7.90939 6.83442 8.27182 6.47199 8.71932 6.47199ZM8.71932 1.61738H5.84205C5.53016 1.61738 5.27005 1.84544 5.22197 2.145L5.21334 2.24732V2.87603H9.34802V2.24732C9.34802 1.9342 9.11996 1.67409 8.8204 1.62601L8.71932 1.61738V1.61738Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconDelete;
