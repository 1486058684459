import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import colors from '../../../styles/colors';
import control from '../../../styles/control';
import spacing from '../../../styles/spacing';
import transition from '../../../styles/transition';

export const inputDisabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const getControlStyle = (): FlattenSimpleInterpolation => css`
  display: flex;
  background-color: ${colors.light[200]};
  border-color: ${control.border.color.base};
  border-style: ${control.border.type};
  border-width: ${control.border.size.base}px;
  border-radius: ${control.radius.base};
  border-radius: ${control.radius.base};
  font-family: sans-serif;
  line-height: 2.5rem;

  padding-left: ${spacing[4]};
  padding-right: ${spacing[4]};
  transition: border-color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base},
    background-color ${transition.timing.base} ${transition.easing.base};

  &:not([type='checkbox']):not([type='radio']):focus,
  &:not([type='checkbox']):not([type='radio']):focus-within {
    outline: none;
    box-shadow: 0 0 0.0625rem 0.1875rem rgb(182 178 170 / 12%);
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${control.border.color.hover};
    background-color: rgb(252 251 248 / 40%);
  }
  &:disabled {
    ${inputDisabledStyles};
    border-color: ${control.border.color.base};
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }
`;

const Control = styled.div`
  ${getControlStyle};
  display: block;
  width: 100%;
`;

export default Control;
