import './src/styles/global.css';
import 'dayjs/locale/fr';

import { FormidableProvider } from '@innedit/formidable';
import { InneditProvider, UtilisateurModel } from '@innedit/innedit-react';
import dayjs from 'dayjs';
import React from 'react';
import { Translation } from 'react-i18next';

import { load } from './src/actions/user';
import Button from './src/components/Button';
import { Message as FormMessage } from './src/components/Form';
import Column from './src/components/Form/Column';
import Columns from './src/components/Form/Columns';
import {
  Message as FieldMessage,
  Template as FieldTemplate,
  Wrapper as FieldWrapper,
} from './src/components/Form/Field';
import { getControlStyle } from './src/components/Form/Field/Control';
import Input, {
  Group as InputGroup,
  GroupItem as InputGroupItem,
} from './src/components/Form/Field/Input';
import Select from './src/components/Form/Field/Select';
import Textarea from './src/components/Form/Field/Textarea';
import Group, {
  Description as GroupDescription,
  Title as GroupTitle,
} from './src/components/Group';
import extendData from './src/datas';
import i18n from './src/i18n';
import reducers from './src/reducers';
import theme from './src/styles/theme';

dayjs.locale('fr');

const initializeStore = store => {
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    // const [p1, espaceId] = window.location.pathname.substring(1).split('/');
    UtilisateurModel.onChange(userChanged => {
      const { user } = store.getState();
      if (user?.unsubscribe) {
        user.unsubscribe();
      }
      if (userChanged) {
        store.dispatch(load(userChanged));
      } else {
        // store.dispatch(isLogout());

        UtilisateurModel.signInAnonymously();
      }
    });
  }
};

const onClientEntry = () => {
  if (
    typeof window !== `undefined` &&
    'true' === process.env.GATSBY_IS_MAINTENANCE &&
    window.location.pathname !== '/maintenance/'
  ) {
    window.location = '/maintenance/';
  }
};

// const onInitialClientRender = () => {
//   console.log('ReactDOM.render has executed');
// };

// const wrapPageElement = ({ element }) => {
//   console.info('wrapPageElement');
//
//   return <>{element}</>;
// };

const wrapRootElement = ({ element }) => (
  <InneditProvider>
    <Translation i18n={i18n}>
      {t => (
        <FormidableProvider
          extendData={extendData}
          extraReducers={reducers}
          getControlStyle={getControlStyle}
          initializeStore={initializeStore}
          sc={{
            button: Button,
            column: Column,
            columns: Columns,
            condition: FieldWrapper,
            fieldMessage: FieldMessage,
            fieldTemplate: FieldTemplate,
            fieldWrapper: FieldWrapper,
            formMessage: FormMessage,
            group: Group,
            groupDescription: GroupDescription,
            groupTitle: GroupTitle,
            input: Input,
            inputGroup: InputGroup,
            inputGroupItem: InputGroupItem,
            select: Select,
            textarea: Textarea,
          }}
          t={t}
          theme={theme}
        >
          {element}
        </FormidableProvider>
      )}
    </Translation>
  </InneditProvider>
);

export {
  onClientEntry,
  // onInitialClientRender,
  // wrapPageElement,
  wrapRootElement,
};
