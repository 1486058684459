import styled from 'styled-components';

import spacing from '../../../styles/spacing';
import Control from './Control';

const Input = styled(Control).attrs({ as: 'input' })`
  &[type='checkbox'] {
    width: auto;
    margin-right: ${spacing[4]};
  }
`;

export const Group = styled.div`
  margin-bottom: ${spacing[4]};
`;

export const GroupItem = styled.div.attrs({ className: 'space-x-3' })`
  display: flex;
  align-items: center;

  input[type='radio'] {
    width: auto;
  }
`;

export default Input;
